/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { RouteComponentProps } from "@reach/router";
import { FC } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Flex } from "../components/Flex";
import { uiConfig } from "../store/auth";
import firebase from "../utils/firebase";
import bgImg from "../assets/jland.jpg";

export const SignIn: FC<RouteComponentProps> = () => {
  return (
    <Flex
      column
      horizontal="center"
      css={css`
        padding-top: 32px;
        height: calc(100vh - 32px);
        background: url(${bgImg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 90%;
      `}
    >
      <h1>Rullebo</h1>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </Flex>
  );
};
