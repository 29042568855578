/*
	Dark background colors: https://coolors.co/202124-303136-3A3C40
	Light background colors: https://coolors.co/ffffff-f1f1f1-e6e6e6

	Color levels
		1 primary
		2 secondary
		3 tertiary
		4 quaternary
		5 quinary
		6 senary
		7 septenary
		8 octonary
		9 nonary
		10 denary
*/

const colors = {
  green: {
    primary: "#C3FF0D",
    secondary: "#197f12",
  },
};

const textBlack = "#1d1d1f";
const textWhite = "#fafafa";

const shadowElevationMap = {
  xs: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
  s: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)",
  m: "0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)",
  l: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
  xl: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)",
  xxl: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)",
  "3xl":
    "0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20)",
  "4xl":
    "0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)",
  "5xl":
    "0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20)",
  "6xl":
    "0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20)",
};

export const light: Theme = {
  name: "light",
  shadow: shadowElevationMap,
  colors: {
    background: {
      primary: "#f1f1f1",
      secondary: "#ffffff",
      tertiary: "#e6e6e6",
    },
    text: {
      black: textBlack,
      white: textWhite,
      primary: textBlack,
    },
    button: {
      primary: "lightblue",
    },
    border: "#c6c6c6",

    ...colors,
  },
};

export const dark: Theme = {
  name: "dark",
  shadow: shadowElevationMap,
  colors: {
    background: {
      primary: "#202124",
      secondary: "#303136",
      tertiary: "#3A3C40",
    },
    text: {
      black: textBlack,
      white: textWhite,
      primary: textWhite,
    },
    button: {
      primary: "lightblue",
    },
    border: "#474747",
    ...colors,
  },
};

export type ThemeName = "light" | "dark";

export type Theme = {
  name: ThemeName;
  colors: {
    background: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
    text: {
      black: string;
      white: string;
      primary: string;
    };
    button: {
      primary: string;
    };
    green: {
      primary: string;
      secondary: string;
    };
    border: string;
  };
  shadow: {
    xs: string;
    s: string;
    m: string;
    l: string;
    xl: string;
    xxl: string;
    "3xl": string;
    "4xl": string;
    "5xl": string;
    "6xl": string;
  };
};
