/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { RouteComponentProps } from "@reach/router";
import {
  addDays,
  addMonths,
  getDaysInMonth,
  isAfter,
  isSameDay,
  isWithinInterval,
  startOfMonth,
  subMonths,
} from "date-fns";
import { readableColor } from "polished";
import React, { FC, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useMeasure from "react-use-measure";
import { Flex } from "../../components/Flex";
import { ModalHandlerType } from "../../components/Modal";
import {
  Booking as BookingType,
  subscribeToBookings,
} from "../../firebase/api";
import { formatLocale, getDayLocale } from "../../utils/dateFnsLocale";
import { margin, padding } from "../../utils/space";
import { useTheme } from "../../utils/useTheme";
import { NewEditBookingModal } from "./NewEditBookingModal";

const bookingColor: Record<string, string> = {
  "andreas.g.skifjeld@gmail.com": "hotpink",
  "alettegs@gmail.com": "coral",
  "henrikgs@gmail.com": "RoyalBlue",
  "camgl@online.no": "lightgreen",
  "loltol56@gmail.com": "pink",
};
const getBookingColor = (email: string) => {
  if (email in bookingColor) {
    return bookingColor[email];
  }

  return "pink";
};

const useBookings = () => {
  const [bookings, setBookings] = useState<BookingType[]>([]);

  useEffect(() => {
    const unsubscribe = subscribeToBookings((data) => {
      setBookings(data);
    });
    return () => unsubscribe();
  }, []);

  return bookings;
};

export const CalendarPage: FC<RouteComponentProps> = () => {
  const [ref, bounds] = useMeasure();
  const modalRef = useRef<ModalHandlerType | null>(null);
  const bookings = useBookings();
  const [month, setMonth] = useState<Date>(startOfMonth(new Date()));
  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(undefined);
  const [selectedBooking, setSelectedBooking] =
    useState<BookingType | undefined>(undefined);

  const handleDateClick = (date: Date) => {
    if (fromDate && toDate) {
      setFromDate(undefined);
      setToDate(undefined);
    } else if (
      fromDate &&
      (isSameDay(date, fromDate) || isAfter(date, fromDate))
    ) {
      setToDate(date);
      modalRef.current?.open();
    } else {
      setFromDate(date);
    }
  };

  const handleBookingClick = (booking: BookingType) => {
    setSelectedBooking(booking);
    modalRef.current?.open();
  };

  const firstDayOfMonth = getDayLocale(month);
  const daysInMonth = [
    ...new Array(getDaysInMonth(month) + firstDayOfMonth)
      .fill(0)
      .map((_, i) => (i >= firstDayOfMonth ? 1 : 0)),
  ];

  const mobileMode = bounds.width < 800;
  const dayWidthCss = css`
    /* 39 is a magic number (16 padding on each side + 7x1px border on each day) */
    width: ${(bounds.width - 39) / 7}px;
  `;

  return (
    <div
      ref={ref}
      css={css`
        padding: 16px;
      `}
    >
      <Flex
        horizontal="space-between"
        vertical="center"
        css={margin.bottom("large")}
      >
        <Flex column vertical="center">
          <strong>{`${formatLocale(month, "MMMM yyyy")}`}</strong>

          <Flex css={margin.top("tiny")}>
            <button onClick={() => setMonth((cs) => subMonths(cs, 1))}>
              Forrige
            </button>
            <span css={padding.horizontal("small")}>|</span>
            <button onClick={() => setMonth((cs) => addMonths(cs, 1))}>
              Neste
            </button>
          </Flex>
        </Flex>

        <div>
          <NewEditBookingModal
            modalRef={modalRef}
            fromDate={fromDate}
            toDate={toDate}
            selectedBooking={selectedBooking}
            onFinish={() => {
              setFromDate(undefined);
              setToDate(undefined);
              setSelectedBooking(undefined);
            }}
          />
        </div>
      </Flex>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-auto-rows: auto;
        `}
      >
        <div css={[dayWidthCss, margin.bottom()]}>
          {mobileMode ? "Man." : "Mandag"}
        </div>
        <div css={[dayWidthCss, margin.bottom()]}>
          {mobileMode ? "Tir." : "Tirsdag"}
        </div>
        <div css={[dayWidthCss, margin.bottom()]}>
          {mobileMode ? "Ons." : "Onsdag"}
        </div>
        <div css={[dayWidthCss, margin.bottom()]}>
          {mobileMode ? "Tor." : "Torsdag"}
        </div>
        <div css={[dayWidthCss, margin.bottom()]}>
          {mobileMode ? "Fre." : "Fredag"}
        </div>
        <div css={[dayWidthCss, margin.bottom()]}>
          {mobileMode ? "Lør." : "Lørdag"}
        </div>
        <div css={[dayWidthCss, margin.bottom()]}>
          {mobileMode ? "Søn." : "Søndag"}
        </div>
        {daysInMonth.map((isInThisMonth, i) => (
          <Day
            key={i}
            isInThisMonth={isInThisMonth}
            dayIndex={i}
            firstDayOfMonth={firstDayOfMonth}
            bookings={bookings}
            month={month}
            handleDateClick={handleDateClick}
            fromDate={fromDate}
            toDate={toDate}
            css={dayWidthCss}
            handleBookingClick={handleBookingClick}
          />
        ))}
      </div>
    </div>
  );
};

const Day: FC<{
  isInThisMonth: 0 | 1;
  dayIndex: number;
  firstDayOfMonth: number;
  bookings: BookingType[];
  month: Date;
  fromDate?: Date;
  toDate?: Date;
  className?: string;
  handleDateClick: (date: Date) => void;
  handleBookingClick: (booking: BookingType) => void;
}> = ({
  isInThisMonth,
  dayIndex,
  firstDayOfMonth,
  bookings,
  month,
  fromDate,
  toDate,
  className,
  handleDateClick,
  handleBookingClick,
}) => {
  const { theme } = useTheme();
  const dayOfMonth = dayIndex - firstDayOfMonth + 1;
  const today = addDays(month, dayOfMonth);
  const isWithin =
    (fromDate && isSameDay(fromDate, today)) ||
    (fromDate &&
      toDate &&
      isWithinInterval(today, {
        start: fromDate,
        end: toDate,
      }));

  const todaysBookings = bookings.filter((booking) =>
    isWithinInterval(today, {
      start: new Date(booking.start),
      end: addDays(new Date(booking.end), 1),
    })
  );

  return (
    <div
      onClick={() => handleDateClick(today)}
      css={css`
        display: grid;
        grid-template-areas:
          "date"
          "101"
          "102"
          "103"
          "104";
        height: 120px;
        border-right: ${isInThisMonth ? "1px solid black" : "none"};
        border-bottom: ${isInThisMonth ? "1px solid black" : "none"};
        cursor: pointer;

        background-color: ${isInThisMonth
          ? isWithin
            ? "hotpink"
            : theme.colors.background.secondary
          : theme.colors.background.primary};

        @media screen and (max-width: 800px) {
          height: 80px;
        }
      `}
      className={className}
    >
      <div
        css={css`
          padding: 8px;
          grid-area: date;

          @media screen and (max-width: 800px) {
            font-size: 14px;
            padding: 2px 8px;
          }
        `}
      >
        {isInThisMonth ? dayOfMonth : ""}
      </div>
      {isInThisMonth
        ? todaysBookings.map((booking) => (
            <Booking
              key={booking.id}
              booking={booking}
              onClick={() => handleBookingClick(booking)}
            />
          ))
        : null}
    </div>
  );
};

const Booking: FC<{ booking: BookingType; onClick: () => void }> = ({
  booking,
  onClick,
}) => {
  const { theme } = useTheme();
  const backgroundColor = getBookingColor(booking.email);

  return (
    <>
      {booking.rooms.map((room) => (
        <button
          key={room}
          css={[
            css`
              grid-area: ${room};
              width: 100%;
              white-space: nowrap;
              display: flex;
              justify-content: flex-start;
              padding: 2px 4px;
              background-color: ${backgroundColor};
              overflow-x: hidden;
              color: ${readableColor(
                backgroundColor,
                theme.colors.text.white,
                theme.colors.text.black
              )};
              @media screen and (max-width: 800px) {
                font-size: 12px;
              }
            `,
            booking.email === "loltol56@gmail.com" &&
              css`
                background: linear-gradient(
                  0deg,
                  rgba(219, 4, 3, 1) 8%,
                  rgba(251, 163, 0, 1) 24%,
                  rgba(255, 255, 0, 1) 41%,
                  rgba(0, 128, 2, 1) 58%,
                  rgba(8, 0, 255, 1) 74%,
                  rgba(128, 0, 128, 1) 92%
                );
                color: ${theme.colors.text.white};
              `,
          ]}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          <strong>{room}:</strong>&nbsp;{booking.email.split("@")[0]}
        </button>
      ))}
    </>
  );
};
