/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

type SpaceSize = "tiny" | "small" | "medium" | "large" | "huge";

export type Space = number | SpaceSize | "auto";

const spaces: Record<SpaceSize, string> = {
  tiny: "4px",
  small: "8px",
  medium: "16px",
  large: "32px",
  huge: "64px",
};

const getSpace = (space?: Space) => {
  if (typeof space === "undefined") {
    return spaces.medium;
  } else if (typeof space === "number") {
    return `${space}px`;
  } else if (space === "auto") {
    return "auto";
  } else {
    return spaces[space];
  }
};

const space = (type: "margin" | "padding") => {
  return {
    top: (space?: Space) => css`
      ${type}-top: ${getSpace(space)};
    `,
    bottom: (space?: Space) => css`
      ${type}-bottom: ${getSpace(space)};
    `,
    left: (space?: Space) => css`
      ${type}-left: ${getSpace(space)};
    `,
    right: (space?: Space) => css`
      ${type}-right: ${getSpace(space)};
    `,
    horizontal: (space?: Space, spaceRight?: Space) => css`
      ${type}-left: ${getSpace(space)};
      ${type}-right: ${getSpace(spaceRight ?? space)};
    `,
    vertical: (space?: Space, spaceBottom?: Space) => css`
      ${type}-top: ${getSpace(space)};
      ${type}-bottom: ${getSpace(spaceBottom ?? space)};
    `,
  };
};

export const margin = space("margin");
export const padding = space("padding");
