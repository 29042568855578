/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { addDays, subHours } from "date-fns";
import React, { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, OutlinedButton } from "../../components/Buttons";
import { Checkbox } from "../../components/Checkbox";
import { Modal, ModalHandlerType } from "../../components/Modal";
import {
  Booking,
  createBooking,
  deleteBooking,
  updateBooking,
} from "../../firebase/api";
import { useAuthInfo } from "../../utils/useAuthInfo";

export const NewEditBookingModal: FC<{
  modalRef: React.MutableRefObject<ModalHandlerType | null>;
  onFinish: () => void;
  fromDate?: Date;
  toDate?: Date;
  selectedBooking?: Booking;
}> = ({ modalRef, fromDate, toDate, selectedBooking, onFinish }) => {
  const { user } = useAuthInfo();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 2));
  const [rooms, setRooms] = useState<string[]>([]);

  useEffect(() => {
    if (selectedBooking) {
      setStartDate(new Date(selectedBooking.start));
      setEndDate(new Date(selectedBooking.end));
      setRooms(selectedBooking.rooms);
    }
    // hack to get the date correct
    if (fromDate) {
      setStartDate(subHours(fromDate, 4));
    }
    if (toDate) {
      setEndDate(subHours(toDate, 4));
    }
  }, [fromDate, toDate, selectedBooking]);

  const handleRoomSelect = (room: string) => {
    setRooms((cs) => {
      const roomIndex = cs.findIndex((r) => r === room);
      const newRooms = [...cs];

      if (roomIndex === -1) {
        return [...newRooms, room];
      } else {
        newRooms.splice(roomIndex, 1);
        return newRooms;
      }
    });
  };

  const handleDeleteBooking = async () => {
    if (window.confirm("Er du sikker på at du vil slette denne bookingen?")) {
      selectedBooking?.id && (await deleteBooking(selectedBooking.id));
      modalRef.current?.close();
    }
  };

  return (
    <Modal
      hasCloseIcon={true}
      button={({ openModal }) => (
        <Button onClick={openModal}>Ny booking</Button>
      )}
      onClose={onFinish}
      ref={modalRef}
    >
      <h3>{selectedBooking ? "Endre" : "Legg til ny"} booking</h3>
      {selectedBooking && <h5>Booking epost: {selectedBooking.email}</h5>}
      <div
        css={css`
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          label {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
              padding: 8px 16px;
              border-radius: 4px;
              border: none;
              height: 100%;
            }

            margin-bottom: 8px;
          }
        `}
      >
        <label>
          <div style={{ minWidth: 70 }}>Fra dato</div>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd.MM.yy"
          />
        </label>
        <label>
          <div style={{ minWidth: 70 }}>Til dato</div>
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="dd.MM.yy"
          />
        </label>
      </div>
      <div
        css={css`
          margin-top: 16px;
          label {
            margin-right: 16px;
            input {
              margin-right: 8px;
            }
          }
        `}
      >
        <Checkbox
          checked={rooms.includes("101")}
          onChange={() => handleRoomSelect("101")}
          title="101"
        />
        <Checkbox
          checked={rooms.includes("102")}
          onChange={() => handleRoomSelect("102")}
          title="102"
        />
        <Checkbox
          checked={rooms.includes("103")}
          onChange={() => handleRoomSelect("103")}
          title="103"
        />
        <Checkbox
          checked={rooms.includes("104")}
          onChange={() => handleRoomSelect("104")}
          title="104"
        />
      </div>
      <div
        css={css`
          margin-top: 16px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          <Button
            disabled={!startDate || !endDate || rooms.length === 0}
            onClick={async () => {
              const params = {
                start: startDate.toISOString(),
                end: endDate.toISOString(),
                email: user.email || "",
                rooms: rooms,
              };

              if (selectedBooking?.id) {
                await updateBooking({ id: selectedBooking.id, ...params });
              } else {
                await createBooking(params);
              }

              modalRef.current?.close();
            }}
          >
            Bekreft
          </Button>
          <div
            css={css`
              width: 16px;
            `}
          />
          <OutlinedButton
            onClick={() => {
              modalRef.current?.close();
            }}
          >
            Avbryt
          </OutlinedButton>
        </div>
        {selectedBooking && (
          <Button
            style={{ backgroundColor: "tomato" }}
            onClick={handleDeleteBooking}
          >
            Slett booking
          </Button>
        )}
      </div>
    </Modal>
  );
};
