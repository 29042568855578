/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { RouteComponentProps } from "@reach/router";
import { partition } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { OutlinedButton } from "../../components/Buttons";
import { Flex } from "../../components/Flex";
import { TextInput } from "../../components/TextInput";
import {
  createShoppingItem,
  markShoppingItemAs,
  ShoppingItem as ShoppingItemType,
  subscribeToShoppingList,
} from "../../firebase/api";
import { margin } from "../../utils/space";
import { useTheme } from "../../utils/useTheme";

export const ShoppingList: FC<RouteComponentProps> = () => {
  const [completedList, notCompletedList] = partition(
    useShoppingList(),
    (item) => item.completed
  );
  const [showCompleted, setShowCompleted] = useState(false);

  const [item, setItem] = useState("");
  const [quantity, setQuantity] = useState("1");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (item && quantity) {
      await createShoppingItem({
        item,
        quantity: parseInt(quantity),
        completed: false,
      });
    }

    setItem("");
    setQuantity("1");
  };

  return (
    <div
      css={css`
        padding: 16px;
      `}
    >
      <form onSubmit={handleSubmit}>
        <Flex>
          <TextInput
            value={item}
            onValue={(val) => setItem(val)}
            label="Vare"
            placeholder="Såpe..."
            css={css`
              width: 100%;
              ${margin.right("small")};
            `}
          />
          <TextInput
            value={quantity}
            onValue={(val) => setQuantity(val)}
            label="Antall"
            type="number"
            placeholder="1"
            min="1"
            css={css`
              width: 64px;
            `}
          />
        </Flex>
        <Flex horizontal="flex-end" css={margin.top("small")}>
          <OutlinedButton type="submit">Legg til</OutlinedButton>
        </Flex>
      </form>

      <Flex column css={margin.top("large")}>
        {notCompletedList.map((item) => (
          <ShoppingItem key={item.id} item={item} />
        ))}
        {notCompletedList.length === 0 && (
          <Flex horizontal="center">Listen er tom 🥳</Flex>
        )}

        <Flex horizontal="center" css={margin.vertical("large")}>
          <OutlinedButton onClick={() => setShowCompleted(!showCompleted)}>
            {showCompleted ? "Skjul" : "Vis"} ferdig
          </OutlinedButton>
        </Flex>

        {showCompleted && (
          <>
            {completedList.map((item) => (
              <ShoppingItem key={item.id} item={item} />
            ))}
          </>
        )}
      </Flex>
    </div>
  );
};

const ShoppingItem = ({ item }: { item: ShoppingItemType }) => {
  const { theme } = useTheme();

  return (
    <Flex horizontal="space-between" css={margin.vertical("small")}>
      <span
        css={css`
          text-decoration: ${item.completed ? "line-through" : "none"};
        `}
      >
        {item.item} ({item.quantity})
      </span>

      <button
        onClick={() => item.id && markShoppingItemAs(item.id, !item.completed)}
        css={css`
          border: 1px solid ${theme.colors.button.primary};
          padding: 2px 8px;
          border-radius: 4px;
        `}
      >
        {item.completed ? "Tilbake" : "Ferdig"}
      </button>
    </Flex>
  );
};

export const useShoppingList = () => {
  const [shoppingList, setShoppingList] = useState<ShoppingItemType[]>([]);

  useEffect(() => {
    const unsubscribe = subscribeToShoppingList((data) => {
      setShoppingList(data);
    });
    return () => unsubscribe();
  }, []);

  return shoppingList;
};
