import { useEffect, useState } from "react";
import { Article, subscribeToArticles } from "../../firebase/api";

export const useArticles = () => {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    const unsubscribe = subscribeToArticles((data) => {
      setArticles(data);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return [articles, loading] as [Article[], boolean];
};
