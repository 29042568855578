/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ComponentPropsWithRef, forwardRef, ReactNode } from "react";

type FlexAlign =
  | "flex-start"
  | "flex-end"
  | "center"
  | "baseline"
  | "stretch"
  | "space-around"
  | "space-between"
  | "space-evenly";

export type FlexProps = {
  column?: boolean;
  wrap?: boolean;
  vertical?: FlexAlign;
  horizontal?: FlexAlign;
  grow?: boolean;
  children?: ReactNode;
  className?: string;
} & ComponentPropsWithRef<"div">;

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
  (
    { column, wrap, vertical, horizontal, grow, children, className, ...rest },
    ref
  ) => {
    return (
      <div
        ref={ref}
        css={[
          css`
            display: flex;
            flex-direction: ${column ? "column" : "row"};
            align-items: ${column ? horizontal : vertical};
            justify-content: ${column ? vertical : horizontal};
          `,
          wrap &&
            css`
              flex-wrap: wrap;
            `,
          grow &&
            css`
              flex-grow: 1;
            `,
        ]}
        className={className}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
