/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { RouteComponentProps } from "@reach/router";
import { FC } from "react";
import bgImg from "../assets/jland.jpg";
import { Flex } from "../components/Flex";

export const LoadingApp: FC<RouteComponentProps> = () => {
  return (
    <Flex
      css={css`
        height: 100vh;
        background: url(${bgImg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 90%;
      `}
    />
  );
};
