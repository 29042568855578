import { useState } from "react";
import { createContainer } from "unstated-next";
import { dark, light, ThemeName } from "../styles/theme";
import storage from "../utils/storage";

export type SystemWidth = "normal" | "wide";

const THEME_STORAGE_KEY: string = "@RulleboStore:SELECTED_THEME";

const useUserSettings = () => {
  const [currentTheme, setCurrentTheme] = useState<ThemeName>(
    storage.getData(THEME_STORAGE_KEY) ?? "light"
  );

  return {
    theme: {
      name: currentTheme === "light" ? "light" : "dark",
      currentTheme: {
        ...(currentTheme === "light" ? light : dark),
      },
      changeTheme: (theme?: ThemeName) => {
        setCurrentTheme((cs) => {
          const newTheme = theme ? theme : cs === "light" ? "dark" : "light";
          storage.storeData(THEME_STORAGE_KEY, newTheme);
          return newTheme;
        });
      },
    },
  };
};

const UserSettingsContainer = createContainer(useUserSettings);
export const UserSettingsProvider = UserSettingsContainer.Provider;
export const useUserSettingsStore = () => UserSettingsContainer.useContainer();
