/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ComponentPropsWithoutRef } from "react";
import { margin } from "../utils/space";
import { Flex } from "./Flex";

type TextInputProps = {
  onValue: (value: string) => void;
  label: string;
  className?: string;
} & ComponentPropsWithoutRef<"input">;

export const TextInput = ({
  onValue,
  label,
  className,
  ...rest
}: TextInputProps) => {
  return (
    <label className={className}>
      <Flex column vertical="center">
        <span css={margin.bottom("tiny")}>{label}</span>
        <input
          onChange={(e) => onValue(e.target.value)}
          css={css`
            padding: 4px 8px;
          `}
          {...rest}
        />
      </Flex>
    </label>
  );
};
