import styled from "@emotion/styled";
import {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Theme } from "../styles/theme";

type ModalProps = {
  button: (params: { openModal: () => void }) => React.ReactNode;
  onClose?: () => void;
  initIsOpen?: boolean;
  width?: number;
  hasCloseIcon?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
};

export type ModalHandlerType = {
  close: () => void;
  open: () => void;
};

type ModalType = ForwardRefRenderFunction<ModalHandlerType, ModalProps>;

const RefModal: ModalType = (
  { button, onClose, initIsOpen = false, width, hasCloseIcon, children, style },
  ref
) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(initIsOpen);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    onClose && onClose();
    setIsOpen(false);
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    function handleKey(event: { key: string }) {
      if (event.key === "Escape") {
        closeModal();
      }
    }

    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [closeModal, isOpen]);

  useImperativeHandle(
    ref,
    () => ({
      close: () => closeModal(),
      open: () => openModal(),
    }),
    [closeModal, openModal]
  );

  return (
    <>
      {button({ openModal })}
      {isOpen ? (
        <StyledModal
          onClick={(event) => {
            event.stopPropagation();
            closeModal();
          }}
        >
          <ModalInner
            ref={modalRef}
            onClick={(event) => {
              event.stopPropagation();
            }}
            width={width}
            style={style}
          >
            {hasCloseIcon && (
              <span
                style={{
                  position: "absolute",
                  top: 12,
                  right: 5,
                }}
              >
                <button onClick={() => closeModal()}>Lukk</button>
              </span>
            )}
            {children}
          </ModalInner>
        </StyledModal>
      ) : null}
    </>
  );
};

const StyledModal = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalInner = styled.div<{ width?: number }>`
  width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) =>
    (theme as Theme).colors.background.primary};
  position: relative;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Modal = forwardRef(RefModal);
