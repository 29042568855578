/** @jsxImportSource @emotion/react */
import { css, ThemeProvider } from "@emotion/react";
import { Link, LinkProps, Router } from "@reach/router";
import React, { FC } from "react";
import { Flex } from "./components/Flex";
import { LoadingApp } from "./components/LoadingApp";
import { ArticlePage } from "./pages/Article/ArticlePage";
import { BrowseArticlesPage } from "./pages/BrowseArticles/BrowseArticlesPage";
import { CalendarPage } from "./pages/Calendar/CalendarPage";
import {
  ShoppingList,
  useShoppingList,
} from "./pages/shoppingList/ShoppingList";
import { SignIn } from "./pages/SignIn";
import { ToDoList, useToDoList } from "./pages/toDoList/ToDoist";
import { AuthProvider, useAuthStore } from "./store/auth";
import { UserSettingsProvider } from "./store/userSettings";
import { GlobalStyles } from "./styles/global";
import { margin } from "./utils/space";
import { useAuthInfo } from "./utils/useAuthInfo";
import { useTheme } from "./utils/useTheme";

export const App: FC = () => {
  return (
    <>
      <AuthProvider>
        <UserSettingsProvider>
          <InnerApp />
        </UserSettingsProvider>
      </AuthProvider>
    </>
  );
};

const InnerApp: FC = () => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthState />
    </ThemeProvider>
  );
};

const AuthState = () => {
  const { state } = useAuthStore();

  switch (state.type) {
    case "loading":
      return <LoadingApp />;

    case "signedOut":
    case "error":
      return <SignIn />;

    case "signedIn":
      return (
        <>
          <Nav />
          <Router>
            <CalendarPage path="/" />
            <ShoppingList path="/handeliste" />
            <ToDoList path="/gjoremaal" />
            <BrowseArticlesPage path="/wiki" />
            <ArticlePage path="/wiki/:articleId" />
          </Router>
        </>
      );
  }
};

const Nav = () => {
  const { user } = useAuthInfo();
  const { signOut } = useAuthStore();
  const { theme } = useTheme();

  const itemsInShoppingList = useShoppingList();
  const numberOfNotCompletedItems = itemsInShoppingList.filter(
    (item) => !item.completed
  ).length;
  const itemsInToDoList = useToDoList();
  const numberOfNotCompletedToDoItems = itemsInToDoList.filter(
    (item) => !item.completed
  ).length;

  return (
    <nav
      css={css`
        padding: 8px 0;
      `}
    >
      <Flex
        horizontal="space-between"
        vertical="center"
        css={css`
          padding: 0 16px;
        `}
      >
        <span>
          <strong>Rullebo</strong>
        </span>

        <Flex vertical="center">
          <span css={margin.right("small")}>
            {user.displayName
              ? user.displayName.split(" ")[0]
              : user.email
              ? user.email.split("@")[0]
              : ""}
          </span>
          <button
            onClick={() => signOut()}
            css={css`
              border: 1px solid ${theme.colors.button.primary};
              padding: 2px 8px;
              border-radius: 4px;
            `}
          >
            Logg&nbsp;ut
          </button>
        </Flex>
      </Flex>

      <Flex
        horizontal="center"
        css={[
          css`
            padding-top: 32px;
            overflow-x: auto;
          `,
        ]}
      >
        <NavLink to="/" css={margin.right()}>
          Kalender
        </NavLink>
        <NavLink
          to="/handeliste"
          css={[
            css`
              position: relative;
            `,
            margin.right(),
          ]}
        >
          Handeliste
          <NotificationsIcon notifications={numberOfNotCompletedItems} />
        </NavLink>
        <NavLink
          to="/gjoremaal"
          css={css`
            position: relative;
          `}
        >
          Gjøremål
          <NotificationsIcon notifications={numberOfNotCompletedToDoItems} />
        </NavLink>
      </Flex>
    </nav>
  );
};

type NavLinkProps = React.PropsWithoutRef<LinkProps<{}>> &
  React.RefAttributes<HTMLAnchorElement>;

const NavLink = (props: NavLinkProps) => {
  const { theme } = useTheme();

  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        return {
          style: {
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            borderRadius: "8px",
            backgroundColor:
              (isCurrent && theme.colors.button.primary) || "transparent",
            color: theme.colors.text.black,
            border: `2px solid ${theme.colors.button.primary}`,
            transition: "all 300ms ease-in-out",
          },
        };
      }}
    />
  );
};

const NotificationsIcon: FC<{ notifications: number }> = ({
  notifications,
}) => {
  if (notifications === 0) {
    return null;
  }

  return (
    <div
      css={css`
        position: absolute;
        top: -8px;
        right: -8px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: red;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        font-size: 13px;
      `}
    >
      {notifications}
    </div>
  );
};
