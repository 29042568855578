/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link, navigate, RouteComponentProps } from "@reach/router";
import { FC, useCallback, useState } from "react";
import { Button } from "../../components/Buttons";
import { Flex } from "../../components/Flex";
import { Modal } from "../../components/Modal";
import { TextInput } from "../../components/TextInput";
import { createArticle } from "../../firebase/api";
import { margin } from "../../utils/space";
import { useTheme } from "../../utils/useTheme";
import { useArticles } from "./useArticles";

export const BrowseArticlesPage: FC<RouteComponentProps> = () => {
  const { theme } = useTheme();
  const [articles] = useArticles();

  const create = useCallback(async (title: string) => {
    const newArticle = await createArticle(title);
    navigate(`/wiki/${newArticle.id}`);
  }, []);

  return (
    <Flex
      column
      horizontal="center"
      css={css`
        padding: 16px;
      `}
    >
      <Flex
        column
        css={css`
          width: 100%;
          max-width: 720px;
        `}
      >
        <Flex vertical="center" horizontal="space-between">
          <h2>Wiki</h2>
          <Modal
            button={({ openModal }) => (
              <Button onClick={openModal}>Lag ny side</Button>
            )}
          >
            <NewArticleModal onCreate={(title) => create(title)} />
          </Modal>
        </Flex>

        {articles.map((article) => {
          return (
            <Link
              to={`/wiki/${article.id}`}
              key={article.id}
              css={margin.bottom()}
            >
              <Flex
                css={css`
                  border: 1px solid gray;
                  padding: 4px 8px;
                  background-color: ${theme.colors.background.tertiary};
                  font-size: 20px;
                `}
              >
                {article.title || article.id}
              </Flex>
            </Link>
          );
        })}
      </Flex>
    </Flex>
  );
};

const NewArticleModal = ({
  onCreate,
}: {
  onCreate: (title: string) => Promise<void>;
}) => {
  const [title, setTitle] = useState("");

  return (
    <Flex column>
      <TextInput
        value={title}
        onValue={(val) => setTitle(val)}
        label="Tittel"
        placeholder="Tittel"
        css={css`
          width: 100%;
        `}
      />

      <Flex horizontal="flex-end" css={margin.top()}>
        <Button onClick={() => onCreate(title)}>Opprett side</Button>
      </Flex>
    </Flex>
  );
};
