import { useUserSettingsStore } from './../store/userSettings';

export const useTheme = () => {
	const { theme } = useUserSettingsStore();

	return {
		name: theme.name,
		theme: theme.currentTheme,
		changeTheme: theme.changeTheme,
	};
};
