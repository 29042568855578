import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import firebase from "../utils/firebase";

// Configure FirebaseUI.
export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

type AuthState =
  | {
      type: "signedOut";
    }
  | {
      type: "signedIn";
      user: firebase.User;
    }
  | {
      type: "loading";
    }
  | {
      type: "error";
      error: string;
    };

type UseAuthType = {
  state: AuthState;
  signOut: () => Promise<void>;
};

export const useAuth = (): UseAuthType => {
  const [state, setState] = useState<AuthState>({ type: "loading" });

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setState({ type: "signedIn", user: user });
        } else {
          setState({ type: "signedOut" });
        }
      });
    return () => unregisterAuthObserver();
  }, []);

  const signOut = async () => {
    await firebase.auth().signOut();
  };

  return {
    state,
    signOut,
  };
};

const AuthContainer = createContainer(useAuth);
export const AuthProvider = AuthContainer.Provider;
export const useAuthStore = (): UseAuthType => AuthContainer.useContainer();
