import { useAuthStore } from "./../store/auth";
import firebase from "./firebase";

type AuthInfo = {
  user: firebase.User;
};
export const useAuthInfo = (): AuthInfo => {
  const { state } = useAuthStore();

  if (state.type !== "signedIn") {
    throw Error("useAuthInfo can't be used outside SignedInApp");
  }

  return {
    user: state.user,
  };
};
