import { useEffect, useState } from "react";

export const useFullscreenDropzone = (
  onFilesDropped: (files: File[]) => void
) => {
  const [dropzoneActive, setDropzoneActive] = useState(false);

  useEffect(() => {
    if (!window) {
      return;
    }

    let lastTarget: EventTarget | null = null;

    const onDragEnter = (event: DragEvent) => {
      const items = Array.prototype.slice.call(event.dataTransfer?.items ?? []);

      if (items.some((item) => item.kind === "file")) {
        lastTarget = event.target;
        setDropzoneActive(true);
      }
    };
    const onDragLeave = (event: DragEvent) => {
      if (event.target === lastTarget) {
        setDropzoneActive(false);
      }
    };
    const onDragOver = (event: DragEvent) => {
      event.preventDefault();
    };
    const onDrop = (event: DragEvent) => {
      event.preventDefault();
      setDropzoneActive(false);

      const files: File[] = Array.prototype.slice.call(
        event.dataTransfer?.files ?? []
      );

      if (files.length > 0) {
        onFilesDropped(files);
      }
    };

    window.addEventListener("dragenter", onDragEnter);
    window.addEventListener("dragleave", onDragLeave);
    window.addEventListener("dragover", onDragOver);
    window.addEventListener("drop", onDrop);

    return () => {
      window.removeEventListener("dragenter", onDragEnter);
      window.removeEventListener("dragleave", onDragLeave);
      window.removeEventListener("dragover", onDragOver);
      window.removeEventListener("drop", onDrop);
    };
  }, [setDropzoneActive, onFilesDropped]);

  return dropzoneActive;
};
