import styled from "@emotion/styled";
import { readableColor, transparentize } from "polished";
import { Theme } from "../styles/theme";

export const Button = styled.button<{ size?: "s" | "m" | "l" }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => (theme as Theme).colors.button.primary};
  color: ${({ theme }) => (theme as Theme).colors.text.black};
  border: 2px solid ${({ theme }) => (theme as Theme).colors.button.primary};
  transition: background-color 300ms ease-in-out;
  transition: color 100ms ease-in-out;

  ${({ size }) => {
    if (size === "s") {
      return {
        padding: "0.2rem 0.8rem",
      };
    }

    // Ignoring case "m" because that is the default size

    if (size === "l") {
      return {
        fontSize: 20,
      };
    }
  }}

  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.2, (theme as Theme).colors.button.primary)};
  }

  &:active {
    background-color: ${({ theme }) =>
      transparentize(0.1, (theme as Theme).colors.button.primary)};
  }

  .svg-inline--fa {
    margin-right: 16px;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const OutlinedButton = styled(Button)<{ active?: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? (theme as Theme).colors.button.primary : "transparent"};
  color: ${({ theme, active }) =>
    active
      ? (theme as Theme).colors.text.white
      : (theme as Theme).colors.text.primary};

  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.9, (theme as Theme).colors.button.primary)};
    color: ${({ theme }) =>
      readableColor(
        (theme as Theme).colors.background.secondary,
        (theme as Theme).colors.text.white,
        (theme as Theme).colors.text.black
      )};
  }

  &:active {
    background-color: ${({ theme }) =>
      transparentize(0.5, (theme as Theme).colors.button.primary)};
    color: ${({ theme }) => (theme as Theme).colors.text.white};
  }
`;

export const IconButton = styled.button<{ active?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) =>
    (theme as Theme).colors.background.tertiary};
  border-radius: 20px;
  padding: 5px 6px;
  border: 1px solid ${({ theme }) => (theme as Theme).colors.button.primary};

  span {
    margin-left: 6px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;

  button {
    border-radius: 0;
    border-right-width: 0;

    &:first-child {
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
    }

    &:last-child {
      border-top-right-radius: 3rem;
      border-bottom-right-radius: 3rem;
      border-right-width: 2px;
    }
  }
`;
