/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { RouteComponentProps } from "@reach/router";
import { FC, useCallback, useState } from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import Showdown from "showdown";
import { v4 as uuidv4 } from "uuid";
import { Button } from "../../components/Buttons";
import { Flex } from "../../components/Flex";
import { TextInput } from "../../components/TextInput";
import { Article as ArticleType, updateArticle } from "../../firebase/api";
import firebase from "../../utils/firebase";
import { margin } from "../../utils/space";
import { useFullscreenDropzone } from "../../utils/useFullscreenDropzone";
import { useArticles } from "../BrowseArticles/useArticles";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const ArticleEditor = ({
  article,
  onStopEdit,
}: {
  article: ArticleType;
  onStopEdit: () => void;
}) => {
  const articleId = article.id;
  const [title, setTitle] = useState(article.title);
  const [content, setContent] = useState(article.content);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const save = useCallback(async () => {
    await updateArticle({ id: articleId, title, content });
    onStopEdit();
  }, [articleId, title, content, onStopEdit]);

  const onFiles = useCallback(async (files: File[]) => {
    const images = files.filter((file) => file.type.startsWith("image"));
    const urls = await Promise.all(
      images.map(async (image) => {
        const ref = firebase
          .storage()
          .ref()
          .child(`wiki/images/${image.name}-${uuidv4()}`);
        await ref.put(image);
        return ref.getDownloadURL();
      })
    );
    const newContent = urls.map((url) => `![](${url})`).join("\n");
    setContent((content) => `${content}\n${newContent}`);
  }, []);
  const dropzoneActive = useFullscreenDropzone(onFiles);

  return (
    <Flex column>
      <Flex horizontal="flex-end">
        <Button onClick={() => save()}>Large</Button>
      </Flex>
      <TextInput
        value={title}
        onValue={(val) => setTitle(val)}
        label="Tittel"
        placeholder="Tittel"
        css={css`
          width: 100%;
          ${margin.bottom()}
        `}
      />

      <span css={margin.bottom("tiny")}>Innhold</span>
      <ReactMde
        value={content}
        onChange={setContent}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(converter.makeHtml(markdown))
        }
        initialEditorHeight={500}
      />

      <input
        type="file"
        onChange={(event) => {
          onFiles(Array.from(event.target.files ?? []));
        }}
        multiple
        value={""}
        css={margin.top()}
      />
      {dropzoneActive && (
        <Flex
          css={css`
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.25);
          `}
          horizontal="center"
          vertical="center"
        >
          <h1
            css={css`
              color: white;
            `}
          >
            Slipp filer for å laste opp
          </h1>
        </Flex>
      )}
    </Flex>
  );
};

const Article = ({ article }: { article: ArticleType }) => {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <ArticleEditor article={article} onStopEdit={() => setEditing(false)} />
    );
  } else {
    return (
      <Flex column css={margin.bottom("large")}>
        <Flex horizontal="space-between" vertical="center">
          <h2 css={margin.right()}>{article.title}</h2>
          <Button onClick={() => setEditing(true)}>Rediger</Button>
        </Flex>

        <div
          css={css`
            width: calc(100%-16px);
            padding: 8px;
            background-color: white;
            border: 1px solid black;
            border-radius: 8px;
          `}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(
                article.content || "Ikke noe innhold ennå..."
              ),
            }}
          />
        </div>
      </Flex>
    );
  }
};

export const ArticlePage: FC<RouteComponentProps<{ articleId: string }>> = ({
  articleId,
}) => {
  const [articles, loading] = useArticles();
  const article = articles.find((article) => article.id === articleId);

  const content = loading ? (
    <div>Laster...</div>
  ) : !article ? (
    <div>Fant ikke artikkel</div>
  ) : (
    <Article article={article} />
  );

  return (
    <Flex
      horizontal="center"
      css={css`
        padding: 16px;
      `}
    >
      <Flex
        column
        css={css`
          width: 100%;
          max-width: 720px;

          img {
            max-width: 100%;
          }
        `}
      >
        {content}
      </Flex>
    </Flex>
  );
};
