import { firebase } from "../utils/firebase";

const bookingsCollection = firebase.firestore().collection("bookings");

export type Booking = {
  id?: string;
  start: string;
  end: string;
  email: string;
  rooms: string[];
};

export const createBooking = async (booking: Booking): Promise<void> => {
  const { start, end, email, rooms } = booking;
  const ref = bookingsCollection.doc();

  await ref.set({
    id: ref.id,
    start,
    end,
    email,
    rooms,
  });
};

export const updateBooking = async (
  booking: Booking & { id: string }
): Promise<void> => {
  const { id, start, end, email, rooms } = booking;
  const ref = bookingsCollection.doc(id);

  await ref.set({
    id: ref.id,
    start,
    end,
    email,
    rooms,
  });
};

export const deleteBooking = async (bookingId: string): Promise<void> => {
  bookingsCollection.doc(bookingId).delete();
};

export const subscribeToBookings = (
  callback: (bookings: Booking[]) => void
) => {
  const unsubscribe = bookingsCollection.onSnapshot((querySnapshot) => {
    const bookings: Booking[] = [];
    querySnapshot.forEach((doc) => {
      bookings.push(doc.data() as Booking);
    });
    callback(bookings);
  });

  return unsubscribe;
};

const shoppingListCollection = firebase.firestore().collection("shoppingList");

export type ShoppingItem = {
  id?: string;
  quantity: number;
  item: string;
  completed: boolean;
};

export const createShoppingItem = async (
  sItem: ShoppingItem
): Promise<void> => {
  const { quantity, item, completed } = sItem;
  const ref = shoppingListCollection.doc();

  await ref.set({
    id: ref.id,
    quantity,
    item,
    completed,
  });
};

export const markShoppingItemAs = async (
  itemId: string,
  completed: boolean
): Promise<void> => {
  const ref = shoppingListCollection.doc(itemId);

  await ref.update({ completed: completed });
};

export const subscribeToShoppingList = (
  callback: (shoppingList: ShoppingItem[]) => void
) => {
  const unsubscribe = shoppingListCollection.onSnapshot((querySnapshot) => {
    const shoppingList: ShoppingItem[] = [];
    querySnapshot.forEach((doc) => {
      shoppingList.push(doc.data() as ShoppingItem);
    });
    callback(shoppingList);
  });

  return unsubscribe;
};

const toDoListCollection = firebase.firestore().collection("toDoList");

export type ToDoItem = {
  id?: string;
  toDo: string;
  completed: boolean;
};

export const createToDoItem = async (sItem: ToDoItem): Promise<void> => {
  const { toDo, completed } = sItem;
  const ref = toDoListCollection.doc();

  await ref.set({
    id: ref.id,
    toDo,
    completed,
  });
};

export const markToDoItemAs = async (
  itemId: string,
  completed: boolean
): Promise<void> => {
  const ref = toDoListCollection.doc(itemId);

  await ref.update({ completed: completed });
};

export const subscribeToToDoList = (
  callback: (toDoList: ToDoItem[]) => void
) => {
  const unsubscribe = toDoListCollection.onSnapshot((querySnapshot) => {
    const toDoList: ToDoItem[] = [];
    querySnapshot.forEach((doc) => {
      toDoList.push(doc.data() as ToDoItem);
    });
    callback(toDoList);
  });

  return unsubscribe;
};

// articles

export type Article = {
  id: string;
  title: string;
  content: string;
  createdAt: string;
  updatedAt?: string;
};

const articlesCollection = firebase.firestore().collection("articles");

export const createArticle = async (title: string) => {
  const ref = articlesCollection.doc();

  const article: Article = {
    id: ref.id,
    title,
    content: "",
    createdAt: new Date().toISOString(),
  };

  await ref.set(article);

  return article;
};

export const updateArticle = async (
  article: Omit<Article, "createdAt" | "updatedAt">
) => {
  const ref = articlesCollection.doc(article.id);

  await ref.set(
    { ...article, updatedAt: new Date().toISOString() },
    { merge: true }
  );
};

export const deleteArticle = async (articleId: string) => {
  await articlesCollection.doc(articleId).delete();
};

export const subscribeToArticles = (
  callback: (articles: Article[]) => void
) => {
  const unsubscribe = articlesCollection.onSnapshot((querySnapshot) => {
    const articles: Article[] = [];
    querySnapshot.forEach((doc) => {
      articles.push(doc.data() as Article);
    });
    callback(articles);
  });

  return unsubscribe;
};
