import firebaseApp from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCTxv21ngtzsQGsItO0UNi0jzydjd284H0",
  authDomain: "rullebo.firebaseapp.com",
  projectId: "rullebo",
  storageBucket: "rullebo.appspot.com",
  messagingSenderId: "539748845511",
  appId: "1:539748845511:web:8ff82179e1ce79c1823be2",
};

firebaseApp.initializeApp(firebaseConfig);

export const auth = () => firebase.auth();

export const firebase = firebaseApp;
export default firebaseApp;
