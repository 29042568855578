const storeData = (key: string, value: unknown): boolean => {
	try {
		const jsonValue = JSON.stringify(value);
		localStorage.setItem(key, jsonValue);
		return true;
	} catch (e) {
		// saving error
		return false;
	}
};

const getData = <T>(key: string, fallbackValue?: T): T | null => {
	try {
		const jsonValue = localStorage.getItem(key);
		return jsonValue ? JSON.parse(jsonValue) : fallbackValue || null;
	} catch (e) {
		// saving error
		return null;
	}
};

const deleteData = (key: string): void => {
	try {
		localStorage.removeItem(key);
	} catch (e) {
		// catch error
	}
};

const storage = {
	storeData,
	getData,
	deleteData,
};

export default storage;
