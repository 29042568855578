import { format, getDay } from "date-fns";
import nbLocale from "date-fns/locale/nb";

export function formatLocale(date: Date, pattern: string) {
  return format(date, pattern, { locale: nbLocale });
}

export function getDayLocale(date: Date): number {
  // we want the first (0) day to be on a monday,
  // not sunday
  const firstDayCorrected = getDay(date) - 1;

  if (firstDayCorrected === -1) {
    return 6;
  }

  return firstDayCorrected;
}
