import { Global, css } from "@emotion/react";
import { normalize } from "polished";
import { FC } from "react";
import { useTheme } from "../utils/useTheme";

export const GlobalStyles: FC = () => {
  const { theme } = useTheme();

  return (
    <Global
      styles={css`
        ${normalize()};

        body {
          background-color: ${theme.colors.background.primary};
          color: ${theme.colors.text.primary};
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        }

        button {
          font: inherit;
          color: inherit;
          background: none;
          cursor: pointer;
          border: none;
          padding: 0;
        }

        button:disabled {
          cursor: default;
        }

        a {
          margin: 0;
          text-decoration: none;
          color: inherit;
        }

        h1 {
          font-weight: bold;
        }

        h2 {
          font-weight: bold;
        }

        h3,
        h4,
        h5,
        h6 {
          font-weight: 500;
          margin: 0;
        }

        #root {
          width: 100%;
          height: 100%;
        }
      `}
    />
  );
};
